<template>
  <div class="add-job pb-4">
    <LoadingOverlay v-if="!fetchJobData || !this.jobById" />
    <ValidationObserver ref="JobTemplate" v-slot="{ handleSubmit }">
      <form @submit.prevent="handleSubmit()">
        <CCardGroup class="has-loading-overlay">
          <CCard>
            <CCardBody class="p-3 pt-3">
              <div class="d-flex justify-content-between">
                <h5 class="main-title pb-3">Job Templates</h5>
                <button class="hand-icon action-button" @click="showHint()">
                  <CIcon
                    name="cil-info"
                    v-c-tooltip="'Hint'"
                    class="hinticon"
                  />
                </button>
              </div>
              <CTabs
                variant="tabs"
                :active-tab="0"
                class="nav-fill nav-justified nav-template"
              >
                <CTab title="Template">
                  <div class="mt-3">
                    <div class="mt-3" v-if="getItems.length">
                      <CDataTable
                        :items="getItems"
                        :fields="fields"
                        hover
                        class="table_"
                      >
                        <template #expand-header>
                          <div class="d-flex justify-content-end">
                            <a
                              class="text-primary"
                              style="font-weight: 500; cursor: pointer"
                              @click="expandAll(!expandAllSection)"
                            >
                              {{
                                expandAllSection
                                  ? "- Collapse All"
                                  : "+ Expand All"
                              }}
                            </a>
                          </div>
                        </template>

                        <template #expand="{ item, index }">
                          <td class="py-2 d-flex justify-content-end">
                            <a
                              class="text-primary"
                              style="font-weight: 500; cursor: pointer"
                              @click="toggleDetails(item, index)"
                            >
                              {{
                                Boolean(item._toggled)
                                  ? "- Collapse"
                                  : "+ Expand"
                              }}
                            </a>
                          </td>
                        </template>
                        <template #details="{ item, index }">
                          <CCollapse
                            :show="Boolean(item._toggled)"
                            :duration="collapseDuration"
                          >
                            <CCardBody>
                              <table
                                class="
                                  table table-section table-striped
                                  border-bottom
                                  mb-0
                                "
                                style="table-layout: fixed"
                              >
                                <tbody>
                                  <tr
                                    v-for="i in getSections(
                                      item.custom_display_id
                                    )"
                                    :key="i.section_id"
                                    style="background-color: white"
                                  >
                                    <td class="form-radio">
                                      <label>
                                        <input
                                          :name="item.custom_display_id"
                                          type="radio"
                                          :value="i.section_id"
                                          :checked="
                                            selected[
                                              `${item.custom_display_id}`
                                            ] == i.section_id
                                          "
                                          @input="
                                            handleRadio(
                                              item.custom_display_id,
                                              i.section_id
                                            )
                                          "
                                        />
                                      </label>
                                    </td>
                                    <td>
                                      <h6>{{ toTitleCase(i.section_name) }}</h6>
                                      <!-- job-section css class used to remove margin bottom space in <p> tag -->
                                      <p
                                        class="job-section"
                                        v-html="i.section_desc"
                                      ></p>
                                    </td>
                                  </tr>
                                  <tr
                                    v-if="
                                      !getSections(item.custom_display_id)
                                        .length
                                    "
                                  >
                                    <td class="text-center">
                                      No Section Template Found
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <CRow
                                v-if="
                                  getSections(item.custom_display_id).length
                                "
                                class="d-flex justify-content-between"
                              >
                                <CButton
                                  class="
                                    mt-2
                                    btn btn-outline-primary
                                    small
                                    ml-3
                                    rounded-0
                                  "
                                  @click="
                                    handleRadio(item.custom_display_id, false)
                                  "
                                  >Clear Selection</CButton
                                >
                                <CButton
                                  class="
                                    mt-2
                                    btn btn-outline-primary
                                    small
                                    mr-3
                                    rounded-0
                                  "
                                  @click="toggleDetails(item, index)"
                                  >Select & Collapse</CButton
                                >
                              </CRow>
                            </CCardBody>
                          </CCollapse>
                        </template>
                      </CDataTable>
                    </div>
                    <div
                      v-if="!(getItems.length > 1) && !isFetchingJobData"
                      class="text-center mt-4 text-danger font-weight-thick"
                    >
                      There are no job templates for this organization - Please
                      update the templates if required!
                    </div>
                  </div>
                </CTab>
                <CTab title="Preview">
                  <JobTemplatePreview
                    :previewSectionsContent="previewSectionsContent"
                    :jobData="jobs"
                  />
                </CTab>
              </CTabs>
            </CCardBody>
          </CCard>
        </CCardGroup>
      </form>
    </ValidationObserver>
    <div
      class="row-sm my-2 action-group d-flex justify-content-between flex-wrap"
    >
      <div class="btn-add-job-cancel">
        <button
          name="cancel"
          class="
            btn
            rounded-2
            px-5
            btn-lg
            mt-3
            float-left
            btn-outline-primary btn-lg
            mr-2
          "
          @click="$parent.goToJobBoard()"
        >
          Cancel
        </button>
      </div>
      <div class="btn-add-job-submit">
        <button
          name="Back"
          class="
            btn
            rounded-2
            px-5
            btn-lg
            mt-3
            float-left
            btn-outline-primary btn-lg
            mr-2
          "
          @click="onSave('back', 1)"
          :disabled="isLoading"
        >
          Back
        </button>

        <button
          name="draft"
          type="submit"
          class="btn rounded-2 px-5 btn-primary btn-lg mt-3 mr-2"
          @click="onSave('draft')"
          :disabled="isLoading"
        >
          Save as Draft
          <CSpinner
            v-if="isLoading == 'draft'"
            class="spinner-border-sm text-white m-1"
          />
        </button>

        <button
          name="next_page"
          type="submit"
          class="btn rounded-2 px-5 btn-primary btn-lg mt-3"
          @click="onSave('next', 3)"
          :disabled="isLoading"
        >
          Next
          <CSpinner
            v-if="isLoading == 'next'"
            class="spinner-border-sm text-white m-1"
          />
        </button>
      </div>
    </div>
    <modal
      modalTitle="NOTIFICATION"
      modalColor="primary"
      modalContent="Please choose all required templates from the sections below!"
      :isShowPopup="hintModalPopup"
      :buttons="['Ok']"
      :modalCallBack="hintModalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import Modal from "../../components/reusable/Modal.vue";
import AddJob from "./AddJob";
import JobTemplatePreview from "./JobTemplatePreview";
import { deepClone } from "@/helpers/helper";

export default {
  mixins: [AddJob],
  components: {
    JobTemplatePreview,
    Modal,
  },
  data: () => ({
    jobs: {},
    payload: {},
    fields: [
      {
        key: "display_name",
        label:
          "Select Sections Required - then Edit in next screen to customize job advert",
        _style: "color: #dd3651; width:70%;",
      },
      { key: "expand", label: "" },
    ],
    details: [],
    collapseDuration: 0,
    selected: {},
    section_ids: [],
    isSectionChanged: false,
    previewSectionsContent: [],
    hintModalPopup: false,
    expandAllSection: false,
    isLoading: false,
    isFetchingJobData: false,
    getItems: [],
  }),
  computed: {
    fetchJobData() {
      if (Object.keys(this.jobById).length) {
        const jobData = this.jobById;
        this.jobs = { ...jobData };
        this.getItems = this.sectionDisplayNames?.map((val, id) => {
          return {
            display_name: val.display_name,
            custom_display_id: val.display_name.split(" ").join("_"),
            id,
            _toggled: false,
          };
        });
      }
      return true;
    },
    sectionDisplayNames() {
      return this.jobById?.preferred_in_advert?.includes("salary")
        ? this.getSectionDisplayNames
        : this.getSectionDisplayNames?.filter(
            (val) => !val?.display_name.toLowerCase().includes("salary")
          );
    },
  },
  methods: {
    handleRadio(name, val) {
      this.selected[`${name}`] = val;
      if (val == false) {
        delete this.selected[`${name}`];
      }
      this.isSectionChanged = true;
      this.section_ids = [];
      for (const [key, value] of Object.entries(this.selected)) {
        this.section_ids.push(value);
      }
      this.setPreviewSectionsContent();
    },
    // Set Preview Content
    setPreviewSectionsContent() {
      this.previewSectionsContent = [];
      for (const [key, value] of Object.entries(this.selected)) {
        let content = this.getSections(key)?.filter((el) => {
          return value == el.section_id;
        });
        if (content.length) {
          let preview = {
            section_id: content[0]?.section_id,
            section_template: content[0],
          };
          this.previewSectionsContent.push(preview);
        }
      }
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    toggleDetails(item) {
      this.getItems = JSON.parse(JSON.stringify(this.getItems));
      this.$set(this.getItems[item.id], "_toggled", !item._toggled);
      this.collapseDuration = 300;
      this.$nextTick(() => {
        this.collapseDuration = 0;
      });
      // To check expand all func
      let temp = false;
      this.getItems.forEach((val) => (val._toggled ? (temp = true) : null));
      if (temp) this.expandAllSection = false;
    },
    // To expand all section
    expandAll(bool) {
      this.getItems?.forEach((item) => {
        this.$set(this.getItems[item.id], "_toggled", bool);
        this.collapseDuration = 300;
        this.$nextTick(() => {
          this.collapseDuration = 0;
        });
      });
      this.expandAllSection = bool;
    },
    // To get Section Descriptions
    getSections(display_name) {
      let data = this.getSectionByDisplayNames[`${display_name}`];
      data = deepClone(data);
      data = Object.keys(data).map((k) => data[k]);
      if (display_name.toLowerCase().includes("salary")) {
        data = data?.filter((val) => {
          if (val?.section_desc.search("%salary_dynamic_text%") != -1) {
            return this.setDynamicSalary(val);
          }
          return val;
        });
      }
      return data || [];
    },
    // To set Salary dynamic text
    setDynamicSalary(val) {
      let job = this.jobById;
      if (job?.salary_min && job?.salary_max) {
        val.section_desc = val.section_desc.replaceAll(
          "%salary_dynamic_text%",
          `${job?.salary_min?.toLocaleString()} ${
            this.getCurrencyForSelectedID(job?.salary_currency_id)[0]?.label
          } to ${job?.salary_max?.toLocaleString()} ${
            this.getCurrencyForSelectedID(job?.salary_currency_id)[0]?.label
          }`
        );
        return val;
      }
    },
    onStep(step) {
      this.$emit("step", step);
    },
    async onSave(selectedBtn, step) {
      const isValid = await this.$refs.JobTemplate.validate();
      if (!isValid) {
        if (selectedBtn == "back") {
          this.onStep(step);
          return;
        }
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.isLoading = selectedBtn;
      let appendAction = [];
      const { job_id, job_uuid, customer_uid } = this.jobById;
      if (this.isSectionChanged) {
        let createSections = {
          customer_uid: customer_uid,
          job_id: job_id,
          sections: [],
        };
        this.section_ids.forEach((val) => {
          createSections.sections.push({
            job_id: job_id,
            section_id: val,
          });
        });
        appendAction = this.createJobDescSections(createSections);
      }
      Promise.all([appendAction]).then(() => {
        if (appendAction?.length != 0) {
          this.fetchJobByID(job_uuid);
          if (selectedBtn == "draft") {
            this.showToast({
              class: "bg-success text-white",
              message: "Updated successfully",
            });
          }
        } else if (selectedBtn == "draft") {
          this.showToast({
            class: "bg-danger text-white",
            message: "No changes made !",
          });
        }
        this.isLoading = false;
        this.payload = {};
        this.isSectionChanged = false;
        if (selectedBtn != "draft") this.onStep(step);
      });
    },
    showHint() {
      this.hintModalPopup = true;
    },
    hintModalCallBack() {
      this.hintModalPopup = false;
    },
  },
  mounted() {
    this.isFetchingJobData = true;
    const customer_uid = this.jobById?.customer_uid;
    const organisation_uid = this.jobById?.organisation_uid;
    const candidate_type_id = this.jobById?.candidate_type_id;
    this.previewSectionsContent = this.jobById?.job_desc_section_templates;
    this.section_ids = [];
    this.fetchSectionDisplayNames({ organisation_uid, customer_uid })
      .then((res) => {
        res.data?.forEach((val) => {
          this.fetchSectionByDisplayNames({
            display_name: val.display_name,
            organisation_uid,
            customer_uid,
            candidate_type_id: ["null", candidate_type_id],
          });
        });
        this.jobById?.job_desc_section_templates?.forEach((val) => {
          let display_name = val?.section_template?.display_name
            .split(" ")
            .join("_");
          this.selected[`${display_name}`] = val.section_id;
          this.section_ids.push(val.section_id);
        });
      })
      .finally(() => (this.isFetchingJobData = false));
  },
};
</script>
