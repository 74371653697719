<template>
  <div class="add-job pb-5">
    <LoadingOverlay v-if="!isFetchingJobData" />
    <ValidationObserver ref="addJobInfo" v-slot="{ handleSubmit }">
      <form id="create" @submit.prevent="handleSubmit()">
        <CCardGroup class="has-loading-overlay">
          <CCard>
            <CCardBody class="p-3">
              <h5 class="text-primary">Job Information</h5>
              <CRow class="mt-4">
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="required col-lg-12 col-md-12"
                      >Organisation</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="organisation"
                          :value="jobs.organisation"
                          @input="handleChangeSelect"
                          :options="
                            options && options['organisation']
                              ? options['organisation']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                          :disabled="isEditJob"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Job Number</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <TextInput
                        name="job_id"
                        :value="jobs.job_id"
                        :disabled="true"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Job Location</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="location_id"
                          :value="jobs.location_id"
                          @input="handleChangeSelect"
                          :options="
                            options && options['location_id']
                              ? options['location_id']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                          :disabled="true"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="required col-lg-12 col-md-12"
                      >Candidate Type</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="candidateType"
                          :value="jobs.candidateType"
                          @input="handleChangeSelect"
                          :options="
                            options && options['candidateType']
                              ? options['candidateType']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                          :disabled="isEditJob"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="required col-lg-12 col-md-12"
                      >Job Title</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="job_title"
                          :value="jobs.job_title"
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3" v-if="!isAHP">
                  <CRow class="row">
                    <label class="required col-lg-12 col-md-12"
                      >Candidate Level</label
                    >
                    <div class="col infoicon-multi-select">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="level"
                          :value="jobs.level"
                          @input="handleChangeSelect"
                          :options="
                            options && options['level_id']
                              ? options['level_id']
                              : []
                          "
                          :taggable="false"
                          :multiple="true"
                          :clearable="true"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select one or more'"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="required col-lg-12 col-md-12">
                      {{
                        isAHP
                          ? "Type of Allied Health Professional"
                          : "Speciality"
                      }}
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="speciality"
                          :value="jobs.speciality"
                          @input="handleChangeSelect"
                          :options="
                            options && options['speciality']
                              ? options['speciality']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol
                  md="6"
                  class="mb-3"
                  v-if="
                    options['subSpeciality'] && options['subSpeciality'].length
                  "
                >
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      {{ isAHP ? "Sub Type / License" : "Sub Speciality" }}
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider>
                        <Select
                          name="sub_speciality"
                          :value="jobs.sub_speciality"
                          @input="handleChangeSelect"
                          :options="
                            options && options['subSpeciality']
                              ? options['subSpeciality']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="true"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <hr />
              <CRow class="mt-3">
                <CCol md="6" class="mb-3">
                  <h5 class="text-primary">Contact</h5>
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12 required"
                      >Point of Contact</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="customer_organisation_contact_id"
                          :value="jobs.customer_organisation_contact_id"
                          @input="handleChangeSelect"
                          :options="
                            options && options['customer_organisation_contact']
                              ? options['customer_organisation_contact']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <hr />
              <h5 class="text-primary mb-4">Optional Requirements</h5>
              <CRow>
                <CCol
                  md="6"
                  class="mb-3"
                  v-if="checkConfigurableFields('is_min_yrs_exp_required')"
                >
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Minimum Years of Experience({{ jobs.min_yrs_of_exp || 0 }}
                      yrs)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <v-slider
                        name="min_yrs_of_exp"
                        :min="0"
                        :max="15"
                        :value="jobs.min_yrs_of_exp || 0"
                        @change="handleSliderMinExp"
                      ></v-slider>
                    </div>
                  </CRow>
                </CCol>
                <CCol
                  md="6"
                  class="mb-3"
                  v-if="checkConfigurableFields('is_language_required')"
                >
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Languages</label>
                    <div class="col infoicon-multi-select">
                      <ValidationProvider v-slot="{ errors }">
                        <Select
                          name="language_ids"
                          :value="jobs.language_ids"
                          @input="handleLanguageSelect"
                          :options="
                            options && options['language']
                              ? options['language']
                              : []
                          "
                          :taggable="false"
                          :multiple="true"
                          :clearable="true"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                    <div class="position-relative multi-select-icon-div">
                      <CIcon
                        name="cil-info"
                        class="hinticon-multi-select"
                        v-c-tooltip="'Select one or more'"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12"
                      >Local License or Eligibility Required</label
                    >
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="regional_license"
                        :value="jobs.regional_license"
                        :options="
                          options && options['regional_license']
                            ? options['regional_license']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol
                  class="mb-3 col-md-6"
                  v-if="checkConfigurableFields('is_gender_required')"
                >
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Gender</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="gender_id"
                        :value="jobs.gender_id"
                        :options="
                          options && options['gender'] ? options['gender'] : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow class="d-flex justify-content-end">
                <CButton
                  class="btn-primary rounded-0 small mr-3"
                  @click="showAdvertModal"
                  >Add to Advert</CButton
                >
              </CRow>
            </CCardBody>
          </CCard>
        </CCardGroup>
        <div class="border-top d-flex justify-content-between py-3 flex-wrap">
          <div class="btn-add-job-cancel">
            <button
              name="cancel"
              class="
                btn
                rounded-2
                px-5
                btn-lg
                mt-3
                float-left
                btn-outline-primary
              "
              @click="$parent.goToJobBoard()"
            >
              Cancel
            </button>
          </div>
          <div class="btn-add-job-submit">
            <button
              name="draft"
              class="btn mr-2 rounded-2 px-5 btn-primary btn-lg mt-3"
              type="button"
              @click="onSave('draft')"
              :disabled="isLoading"
            >
              <CSpinner
                v-if="isLoading == 'draft'"
                class="spinner-border-sm text-white m-1"
              />Save as Draft
            </button>
            <button
              name="next"
              class="btn rounded-2 px-5 btn-primary btn-lg mt-3"
              type="button"
              @click="onSave('next')"
              :disabled="isLoading"
            >
              Next
              <CSpinner
                v-if="isLoading == 'next'"
                class="spinner-border-sm text-white m-1"
              />
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <AdvertModal :isShowPopup="isShowPopup" :jobData="jobs" />
  </div>
</template>

<script>
const debounce = require("lodash.debounce");
import Vue from "vue";
import m from "moment";
import AddJob from "./AddJob";
import { mapActions } from "vuex";
import AdvertModal from "./JobInfoAdvertModal";

export default {
  mixins: [AddJob],
  components: {
    AdvertModal,
  },
  data() {
    return {
      jobs: {
        pref_nationality: [{ label: "All", code: 0 }],
        qualificationRegion: [{ label: "All", code: 0 }],
        qualificationCountry: [{ label: "All", code: 0 }],
        maximum_age: 58,
        regional_license: "n/a",
        gender_id: null,
        language_ids: [{ label: "English", code: 1 }],
        preferred_in_advert: [],
        other_areas_of_expertise_ids: [{ label: "All", code: 0 }],
        experience_in_years: null,
        special_interest_ids_for_ahp: [{ label: "All", code: 0 }],
      },
      payload: {},
      isShowPopup: false,
      isLoading: false,
    };
  },
  computed: {
    isFetchingJobData() {
      if (Object.keys(this.jobById).length) {
        let jobData = this.jobById;
        this.jobs = {
          ...this.jobById,
          organisation: jobData?.organisation
            ? {
                label: jobData?.organisation.name,
                code: jobData?.organisation.organisation_id,
                organisation: jobData?.organisation,
              }
            : null,
          location_id: jobData?.location_id
            ? {
                label: jobData?.location?.location,
                code: jobData?.location_id,
                country_id: jobData?.location?.country_id,
              }
            : null,
          contact: jobData?.organisation?.main_contact?.name,
          candidateType: jobData?.candidate_type
            ? {
                label: jobData?.candidate_type?.candidate_type,
                code: jobData?.candidate_type?.candidate_type_id,
              }
            : null,
          level: jobData?.level_ids
            ? this.getLevelForSelectedId(jobData?.level_ids)
            : null,
          speciality: jobData?.specialities
            ? jobData?.specialities
                ?.filter(
                  (val) => val.is_current == true || val.is_current == null
                )
                ?.map((val) => {
                  return {
                    label: val.speciality?.speciality,
                    code: val.speciality_id,
                  };
                })[0]
            : null,
          previous_experience_in_years: jobData?.specialities
            ? jobData?.specialities?.filter(
                (val) => val.is_current == true || val.is_current == null
              )[0]?.experience_in_years
            : null,
          experience_in_years: jobData?.specialities
            ? jobData?.specialities?.filter(
                (val) => val.is_current == true || val.is_current == null
              )[0]?.experience_in_years
            : null,
          sub_speciality: jobData?.specialities[0]?.sub_speciality
            ?.sub_speciality
            ? {
                label: jobData?.specialities[0]?.sub_speciality?.sub_speciality,
                code: jobData?.specialities[0]?.sub_speciality_id,
              }
            : null,
          previous_other_areas_of_expertise_ids:
            jobData?.specialities?.length > 1
              ? jobData?.specialities
                  ?.filter((val) => val.is_current == false)
                  .map((val) => {
                    return {
                      label: val.speciality?.speciality,
                      code: val.speciality_id,
                    };
                  })
              : [{ label: "All", code: 0 }],
          other_areas_of_expertise_ids:
            jobData?.specialities?.length > 1
              ? jobData?.specialities
                  ?.filter((val) => val.is_current == false)
                  .map((val) => {
                    return {
                      label: val.speciality?.speciality,
                      code: val.speciality_id,
                    };
                  })
              : [{ label: "All", code: 0 }],
          special_interest_ids: jobData?.specialities[0]?.special_interest_ids
            ? this.getSpecialInterestForSelectedId(
                jobData?.specialities[0]?.special_interest_ids
              )
            : null,
          special_interest_ids_for_ahp: jobData?.specialities[0]
            ?.special_interest_ids
            ? this.getSpecialInterestForSelectedId(
                jobData?.specialities[0]?.special_interest_ids
              )
            : [{ label: "All", code: 0 }],
          job_speciality_id: jobData?.specialities[0]?.job_speciality_id,
          job_approval:
            jobData?.job_users?.map((val) => ({
              label: `${val.customer_user?.user?.first_name} ${val.customer_user?.user?.surname}`,
              code: val.customer_user_id,
            })) || null,
          candidate_reviewer:
            jobData?.job_candidate_users?.map((val) => ({
              label: `${val.customer_user?.user?.first_name} ${val.customer_user?.user?.surname}`,
              code: val.customer_user_id,
            })) || null,
          qualificationRegion: jobData?.qualification_region_ids
            ? this.getRegionNameForSelectedId(jobData?.qualification_region_ids)
            : [{ label: "All", code: 0 }],
          qualificationCountry: jobData?.qualification_country_ids
            ? this.getCountryNameForSelectedId(
                jobData?.qualification_country_ids
              )
            : [{ label: "All", code: 0 }],
          gender_id: jobData?.gender_id || null,
          pref_nationality: jobData?.countries_included?.length
            ? jobData.countries_included.map(({ country }) => {
                return {
                  label: country.nationality,
                  code: country.country_id,
                };
              })
            : [{ label: "All", code: 0 }],
          expiry_review_date: jobData?.expiry_review_date,
          regional_license: jobData?.regional_license || "n/a",
          language_ids: jobData?.language_ids
            ? this.getLanguageForSelectedId(jobData.language_ids)
            : [{ label: "English", code: 1 }],
          min_yrs_of_exp: jobData?.min_yrs_of_exp,
          preferred_in_advert: jobData?.preferred_in_advert,
          customer_organisation_contact_id:
            jobData?.customer_organisation_contact_id
              ? this.getOrgContactForSelectedId({
                  customer_organisation_contact_id:
                    jobData?.customer_organisation_contact_id,
                })
              : [],
          customer_organisation_contact: this.getOrgContacts || [],
        };
      }
      return true;
    },
    options() {
      return {
        gender: [
          { id: 1, label: "Male" },
          { id: 2, label: "Female" },
          { id: null, label: "Any" },
        ],
        regional_license: [
          { id: "required", label: "Required" },
          { id: "preferred", label: "Preferred" },
          { id: "n/a", label: "N/A" },
        ],
        candidateType: this.candidateType || [],
        level_id:
          this.candidateLevelByTypeAndCountry({
            candidateType: this.jobs?.candidateType?.code,
            country_id: 181,
          }) || [],
        organisation: this.organizationId || [],
        location_id:
          this.getLocationByOrganisation(this.jobs?.organisation?.code) || [],
        speciality:
          this.specialityByTypeAndCountry({
            candidateType: this.jobs?.candidateType?.code,
            country_id: null,
          }) || [],
        subSpeciality:
          this.subSpecialityFilterBySpeciality({
            speciality: this.jobs?.speciality?.code,
          }) || [],
        nationality:
          [
            { code: 0, label: "All" },
            ...this.getNationalityCountryInfo.map(
              ({ nationality, country_id }) => ({
                code: country_id,
                label: nationality,
              })
            ),
          ] || [],
        qualification_region:
          this.sortOrganisationRegions(this.getOrganisationRegions) || [],
        qualification_country: this.getQualificationCountryByRegion,
        language: this.locationLanguage || [],
        special_interest:
          this.specialInterestFilterBySpeciality({
            speciality: this.jobs?.speciality?.code,
          }) || [],
        special_interest_for_ahp:
          this.specialInterestFilterAHP({
            candidate_type_id: this.jobs?.candidateType?.code,
            speciality: null,
          }) || [],
        candidate_reviewer: this.getContactUserByTypeForJob({
          customer_user_type_id: 1,
        }),
        job_approval: this.getContactUserByTypeForJob({
          customer_user_type_id: 4,
        }),
        customer_organisation_contact_id: this.jobs
          ?.customer_organisation_contact_id
          ? this.getOrgContactForSelectedId({
              customer_organisation_contact_id:
                this.jobs?.customer_organisation_contact_id,
            })
          : [],
        customer_organisation_contact: this.getOrgContacts || [],
      };
    },
    locationLanguageReOrder() {
      return (
        this.locationLanguage.length &&
        this.locationLanguage.sort((a, b) => {
          return a.code === 1 ? -1 : 1;
        })
      );
    },
    isEditJob() {
      return this.jobs.job_id ? true : false;
    },
    isNurse() {
      return this.jobs.candidateType?.code == 2 ? true : false;
    },
    isAHP() {
      return this.jobs.candidateType?.code == 3 ? true : false;
    },
  },
  watch: {
    getOrganisationRegions() {
      this.checkOptionsAndValuesForMultiSelect(
        "qualificationRegion",
        "qualification_region"
      );
    },
    getQualificationCountryByRegion() {
      this.checkOptionsAndValuesForMultiSelect(
        "qualificationCountry",
        "qualification_country"
      );
    },
    "options.level_id"() {
      this.checkOptionsAndValues("level", "level_id");
    },
    "options.special_interest"() {
      this.checkOptionsAndValues("special_interest_ids", "special_interest");
    },
    "options.candidate_reviewer"() {
      this.checkOptionsAndValues("candidate_reviewer", "candidate_reviewer");
    },
    "options.job_approval"() {
      this.jobs.job_approval = this.options?.job_approval;
    },
  },
  mounted() {
    if (!Object.keys(this.jobById).length) {
      this.handleChangeSelect("organisation", this.options["organisation"][0]);
      this.handleChangeSelect("location_id", this.options["location_id"][0]);
      this.jobs.contact =
        this.organizationId[0]?.organisation?.main_contact?.name;
      this.jobs.job_approval = this.options?.job_approval;
    }
  },
  methods: {
    ...mapActions(["createJobSpeciality"]),
    handleSliderMaxAge: debounce(function (value) {
      Vue.set(this.jobs, "maximum_age", value);
      Vue.set(this.payload, "maximum_age", value);
    }, 500),
    handleSliderMinExp: debounce(function (value) {
      Vue.set(this.jobs, "min_yrs_of_exp", value);
      Vue.set(this.payload, "min_yrs_of_exp", value);
    }, 500),
    handleSliderMinExpSpeciality: debounce(function (value) {
      Vue.set(this.jobs, "experience_in_years", value);
      Vue.set(this.payload, "experience_in_years", value);
    }, 500),
    sortOrganisationRegions(data) {
      if (data) {
        let tierData = [];
        let otherData = [];
        data.forEach((val) => {
          if (val.code == 18 || val.code == 19 || val.code == 20)
            tierData.push(val);
          else otherData.push(val);
        });
        data = tierData.concat(otherData);
        return [{ code: 0, label: "All" }, ...data];
      }
    },
    checkOptionsAndValues(name, option) {
      let data = [];
      if (this.jobs[name]?.length) {
        let notFound = false;
        this.jobs[name].forEach((val) => {
          let hasFound = false;
          this.options[option].some((options) => options.code == val.code)
            ? (hasFound = true)
            : (notFound = true);
          if (hasFound) data.push(val);
        });
        if (notFound) this.handleChangeSelect(name, data);
      }
    },
    checkOptionsAndValuesForMultiSelect(name, option) {
      let data = [];
      if (this.jobs[name]?.length) {
        this.jobs[name].forEach((val) => {
          let hasFound = this.options[option].some(
            (options) => options.code == val.code
          );
          if (hasFound) data.push(val);
        });
        this.handleMultiSelectArrayForCountries(name, data);
      }
    },
    onStep(step) {
      this.$emit("step", step);
    },
    async onSave(selectedBtn) {
      const isValid = await this.$refs.addJobInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      this.isLoading = selectedBtn;
      if (Object.keys(this.payload).length) {
        let finalPayload = {
          job_id: this.jobs?.job_id,
          customer_uid: this.jobs?.customer_uid,
          organisation: this.jobs.organisation?.code,
          candidate_type_id: this.jobs?.candidateType?.code,
          level_ids: this.jobs?.level?.map((val) => val.code) || null,
          job_title: this.jobs?.job_title,
          speciality_id: this.jobs?.speciality?.code,
          sub_speciality_id: this.jobs?.sub_speciality?.code || null,
          job_candidate_users: this.jobs?.job_approval
            ?.map((val) => val.code)
            .concat(
              this.jobs?.candidate_reviewer?.map((val) => val.code) || []
            ),
          qualification_region_ids:
            this.jobs?.qualificationRegion[0].label != "All"
              ? this.jobs?.qualificationRegion?.map((val) => val.code)
              : null,
          qualification_country_ids:
            this.jobs?.qualificationCountry[0].label != "All"
              ? this.jobs?.qualificationCountry?.map((val) => val.code)
              : null,
          qualification_regions_all:
            this.jobs?.qualificationRegion[0].label != "All" ? null : true,
          qualification_countries_all:
            this.jobs?.qualificationCountry[0].label != "All" ? null : true,
          location_id: this.jobs.location_id?.code || null,
          gender_id: this.jobs?.gender_id || null,
          maximum_age: this.jobs?.maximum_age || null,
          countries_included:
            this.jobs?.pref_nationality[0].label != "All"
              ? this.jobs?.pref_nationality
              : null,
          language_ids:
            this.jobs?.language_ids[0].label != "N/A"
              ? this.jobs?.language_ids?.map((val) => val.code)
              : null,
          regional_license: this.jobs?.regional_license,
          min_yrs_of_exp: this.jobs?.min_yrs_of_exp || 0,
          expiry_review_date: m().add(30, "days").format("YYYY-MM-DD"),
          special_interest_ids: this.isAHP
            ? this.jobs?.special_interest_ids_for_ahp[0].label != "All"
              ? this.jobs?.special_interest_ids_for_ahp?.map((val) => val.code)
              : null
            : this.jobs?.special_interest_ids?.map((val) => val.code),
          preferred_in_advert: this.jobs?.preferred_in_advert?.length
            ? this.jobs?.preferred_in_advert
            : null,
          other_areas_of_expertise_ids:
            this.jobs?.other_areas_of_expertise_ids[0].label != "All"
              ? this.jobs?.other_areas_of_expertise_ids?.map((val) => val.code)
              : null,
          experience_in_years: this.jobs?.experience_in_years || null,
          customer_organisation_contact_id:
            this.jobs?.customer_organisation_contact_id?.code,
        };
        if (this.jobById && this.jobById.job_id) {
          this.editJobs(selectedBtn, finalPayload);
        } else {
          let appendAction = [];
          const {
            organisation,
            speciality_id,
            sub_speciality_id,
            special_interest_ids,
            countries_included,
            other_areas_of_expertise_ids,
            experience_in_years,
            ...rest
          } = finalPayload;
          this.addJobs({ ...rest, organisation }).then((res) => {
            const { job_id, job_uuid, customer_uid } = res.data;
            this.$parent.jobUUID = job_uuid;
            appendAction = this.createJobSpeciality({
              job_id,
              customer_uid,
              speciality_id,
              sub_speciality_id,
              special_interest_ids,
              other_areas_of_expertise_ids,
              experience_in_years,
            });
            if (countries_included && countries_included.length) {
              countries_included.forEach((item) => {
                appendAction = this.createJobCountry({
                  job_id,
                  customer_uid,
                  country: item,
                });
              });
            }
            Promise.all([appendAction]).then((final_res) => {
              this.fetchJobByID(job_uuid).then((response) => {
                this.isLoading = false;
                if (selectedBtn == "next") this.onStep(1);
                this.showToast({
                  class: "bg-success text-white",
                  message: "Saved successfully!",
                });
                this.payload = {};
              });
            });
          });
        }
      } else {
        if (!this.jobById?.job_id) {
          this.showToast({
            class: "bg-danger text-white",
            message: "Please fill mandatory fields!",
          });
          return;
        }
        if (selectedBtn == "draft") {
          this.showToast({
            class: "bg-danger text-white",
            message: "No changes made !",
          });
          this.isLoading = false;
        }
        if (selectedBtn == "next") this.onStep(1);
      }
    },
    editJobs(selectedBtn, finalPayload) {
      const { job_id, job_uuid, customer_uid } = finalPayload;
      if (
        this.jobs.candidateType.code !=
        this.jobById.candidate_type.candidate_type_id
      ) {
        this.jobById?.benefits.forEach((ele) => {
          let deletebenefit = {
            job_id,
            customer_uid,
            job_benefit_id: ele.job_benefit_id,
          };
          this.deleteBenefitWithoutToast(deletebenefit);
        });
      }

      if (
        finalPayload.speciality != this.jobById.specialities[0].speciality_id ||
        finalPayload.sub_speciality !=
          this.jobById.specialities[0]?.sub_speciality_id ||
        finalPayload.special_interest_ids !=
          this.jobById.specialities[0]?.special_interest_ids ||
        finalPayload.other_areas_of_expertise_ids !=
          this.jobs?.previous_experience_in_years ||
        finalPayload.experience_in_years !=
          this.jobs?.previous_experience_in_years
      ) {
        let specialityPayload = {
          customer_uid,
          job_id,
          job_speciality_id: this.jobById.specialities.filter(
            (val) => val.is_current == true || val.is_current == null
          )[0].job_speciality_id,
          speciality_id: finalPayload.speciality_id,
          sub_speciality_id: finalPayload.sub_speciality_id,
          special_interest_ids: finalPayload.special_interest_ids,
          experience_in_years: finalPayload.experience_in_years,
          other_areas_of_expertise_ids:
            finalPayload.other_areas_of_expertise_ids,
        };
        this.updateJobSpeciality(specialityPayload);
      }

      const { countries_included } = finalPayload;
      let existing_country = this.jobById.countries_included;
      if (countries_included || existing_country) {
        let createCountry = [];
        let deleteCountry = [];
        if (countries_included?.length) {
          countries_included.forEach((ele) => {
            if (existing_country?.length) {
              existing_country.some((val) => ele.code == val.include_country_id)
                ? null
                : createCountry.push(ele);
              return;
            }
            createCountry.push(ele);
          });
        }
        if (existing_country?.length) {
          existing_country.forEach((ele) => {
            if (countries_included?.length) {
              countries_included.some(
                (val) => val.code == ele.include_country_id
              )
                ? null
                : deleteCountry.push(ele);
              return;
            }
            deleteCountry.push(ele);
          });
        }

        if (createCountry?.length || deleteCountry?.length) {
          let appendAction = [];
          createCountry.forEach((item) => {
            appendAction = this.createJobCountry({
              job_id,
              customer_uid,
              country: item,
            });
          });
          deleteCountry.forEach(({ job_country_include_id }) => {
            appendAction = this.deleteJobCountryById({
              job_id,
              customer_uid,
              job_country_include_id,
            });
          });
          Promise.all([appendAction]).then((res) => {
            this.fetchJobByID(job_uuid);
          });
        }
      }

      if (finalPayload && Object.keys(finalPayload).length) {
        const {
          organisation,
          speciality_id,
          sub_speciality_id,
          special_interest_ids,
          countries_included,
          ...rest
        } = finalPayload;
        this.updateJobSteps({ organisation, ...rest }).then((res) => {
          this.payload = {};
          this.isLoading = false;
          if (selectedBtn == "next") this.onStep(1);
        });
      }
    },
    showAdvertModal() {
      this.isShowPopup = true;
    },
    checkConfigurableFields(field) {
      if (this.getConfigurableFields?.job?.hasOwnProperty(field)) {
        return this.getConfigurableFields.job[String(field)] ? true : false;
      } else return true;
    },
  },
};
</script>
