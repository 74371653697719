<template>
  <div class="add-job">
    <div class="mt-3 p-3 col-12 preview-box">
      <div class="border p-3">
        <div v-if="sectionTemplate">
          <p v-html="sectionTemplate"></p>
        </div>
        <div v-if="preferred_in_advert" class="mt-3">
          <h5>
            <strong>Additional Requirements:</strong>
          </h5>
          <p class="mt-2" v-html="preferred_in_advert"></p>
        </div>
        <div v-if="benefits" class="mt-3">
          <h5>
            <strong>Benefits Package:</strong>
          </h5>
          <p class="mt-2" v-html="benefits"></p>
        </div>
        <div v-if="jobAdditionalInfo" class="mt-3">
          <h5>
            <strong>Additional Information:</strong>
          </h5>
          <p class="mt-2" v-html="jobAdditionalInfo"></p>
        </div>
      </div>
    </div>
    <div v-if="!sectionTemplate && !jobAdditionalInfo" class="mt-3 text-center">
      <h5>No Preview Available</h5>
    </div>
  </div>
</template>
<script>
import AddJob from "./AddJob";
import { deepClone } from "../../helpers/helper";
export default {
  name: "JobTemplatePreview",
  props: ["previewSectionsContent", "jobData"],
  mixins: [AddJob],
  computed: {
    sectionTemplate() {
      let data = deepClone(this.jobById);
      data.job_desc_section_templates = this.previewSectionsContent;
      return this.jobSections("preview", data);
    },
    preferred_in_advert() {
      return this.additionalRequirements(this.jobById);
    },
    benefits() {
      return this.jobBenefits(this.jobById);
    },
    jobAdditionalInfo() {
      let AdditionalInfoDisp;
      if (this.jobById?.job_details?.length) {
        AdditionalInfoDisp = this.jobById.job_details.reduce(
          (list, { detail_text }) => {
            return list + `<li>${detail_text}</li>`;
          },
          ""
        );
      }
      return `<ul>${this.jobById.full_time ? "<li>Full Time</li>" : ""}${
        this.jobById.part_time ? "<li>Part Time</li>" : ""
      }${this.jobById.permanent_work ? "<li>Permanent Work</li>" : ""}${
        this.jobById.temporary_work ? "<li>Temporary Work</li>" : ""
      }${AdditionalInfoDisp?.length ? AdditionalInfoDisp : ""}</ul> `;
    },
  },
};
</script>
