var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"add-job pb-5"},[(!_vm.isFetchingJobData)?_c('LoadingOverlay'):_vm._e(),_c('ValidationObserver',{ref:"addJobInfo",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{attrs:{"id":"create"},on:{"submit":function($event){$event.preventDefault();return handleSubmit()}}},[_c('CCardGroup',{staticClass:"has-loading-overlay"},[_c('CCard',[_c('CCardBody',{staticClass:"p-3"},[_c('h5',{staticClass:"text-primary"},[_vm._v("Job Information")]),_c('CRow',{staticClass:"mt-4"},[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Organisation")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"organisation","value":_vm.jobs.organisation,"options":_vm.options && _vm.options['organisation']
                            ? _vm.options['organisation']
                            : [],"taggable":false,"multiple":false,"clearable":false,"error":errors[0],"disabled":_vm.isEditJob},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1),_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Job Number")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('TextInput',{attrs:{"name":"job_id","value":_vm.jobs.job_id,"disabled":true}})],1)])],1)],1),_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Job Location")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"location_id","value":_vm.jobs.location_id,"options":_vm.options && _vm.options['location_id']
                            ? _vm.options['location_id']
                            : [],"taggable":false,"multiple":false,"clearable":false,"error":errors[0],"disabled":true},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1),_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Candidate Type")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"candidateType","value":_vm.jobs.candidateType,"options":_vm.options && _vm.options['candidateType']
                            ? _vm.options['candidateType']
                            : [],"taggable":false,"multiple":false,"clearable":false,"error":errors[0],"disabled":_vm.isEditJob},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1)],1),_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Job Title")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":"job_title","value":_vm.jobs.job_title,"error":errors[0]},on:{"input":_vm.handleInput}})]}}],null,true)})],1)])],1),(!_vm.isAHP)?_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v("Candidate Level")]),_c('div',{staticClass:"col infoicon-multi-select"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"level","value":_vm.jobs.level,"options":_vm.options && _vm.options['level_id']
                            ? _vm.options['level_id']
                            : [],"taggable":false,"multiple":true,"clearable":true,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1),_c('div',{staticClass:"position-relative multi-select-icon-div"},[_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Select one or more'),expression:"'Select one or more'"}],staticClass:"hinticon-multi-select",attrs:{"name":"cil-info"}})],1)])],1):_vm._e()],1),_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"required col-lg-12 col-md-12"},[_vm._v(" "+_vm._s(_vm.isAHP ? "Type of Allied Health Professional" : "Speciality")+" ")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"speciality","value":_vm.jobs.speciality,"options":_vm.options && _vm.options['speciality']
                            ? _vm.options['speciality']
                            : [],"taggable":false,"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1),(
                  _vm.options['subSpeciality'] && _vm.options['subSpeciality'].length
                )?_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v(" "+_vm._s(_vm.isAHP ? "Sub Type / License" : "Sub Speciality")+" ")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',[_c('Select',{attrs:{"name":"sub_speciality","value":_vm.jobs.sub_speciality,"options":_vm.options && _vm.options['subSpeciality']
                            ? _vm.options['subSpeciality']
                            : [],"taggable":false,"multiple":false,"clearable":true},on:{"input":_vm.handleChangeSelect}})],1)],1)])],1):_vm._e()],1),_c('hr'),_c('CRow',{staticClass:"mt-3"},[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('h5',{staticClass:"text-primary"},[_vm._v("Contact")]),_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12 required"},[_vm._v("Point of Contact")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"customer_organisation_contact_id","value":_vm.jobs.customer_organisation_contact_id,"options":_vm.options && _vm.options['customer_organisation_contact']
                            ? _vm.options['customer_organisation_contact']
                            : [],"taggable":false,"multiple":false,"clearable":false,"error":errors[0]},on:{"input":_vm.handleChangeSelect}})]}}],null,true)})],1)])],1)],1),_c('hr'),_c('h5',{staticClass:"text-primary mb-4"},[_vm._v("Optional Requirements")]),_c('CRow',[(_vm.checkConfigurableFields('is_min_yrs_exp_required'))?_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v(" Minimum Years of Experience("+_vm._s(_vm.jobs.min_yrs_of_exp || 0)+" yrs) ")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('v-slider',{attrs:{"name":"min_yrs_of_exp","min":0,"max":15,"value":_vm.jobs.min_yrs_of_exp || 0},on:{"change":_vm.handleSliderMinExp}})],1)])],1):_vm._e(),(_vm.checkConfigurableFields('is_language_required'))?_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Languages")]),_c('div',{staticClass:"col infoicon-multi-select"},[_c('ValidationProvider',{scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('Select',{attrs:{"name":"language_ids","value":_vm.jobs.language_ids,"options":_vm.options && _vm.options['language']
                            ? _vm.options['language']
                            : [],"taggable":false,"multiple":true,"clearable":true,"error":errors[0]},on:{"input":_vm.handleLanguageSelect}})]}}],null,true)})],1),_c('div',{staticClass:"position-relative multi-select-icon-div"},[_c('CIcon',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:('Select one or more'),expression:"'Select one or more'"}],staticClass:"hinticon-multi-select",attrs:{"name":"cil-info"}})],1)])],1):_vm._e()],1),_c('CRow',[_c('CCol',{staticClass:"mb-3",attrs:{"md":"6"}},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Local License or Eligibility Required")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('RadioButton',{attrs:{"name":"regional_license","value":_vm.jobs.regional_license,"options":_vm.options && _vm.options['regional_license']
                          ? _vm.options['regional_license']
                          : []},on:{"change":_vm.handleChangeRadio}})],1)])],1),(_vm.checkConfigurableFields('is_gender_required'))?_c('CCol',{staticClass:"mb-3 col-md-6"},[_c('CRow',{staticClass:"row"},[_c('label',{staticClass:"col-lg-12 col-md-12"},[_vm._v("Gender")]),_c('div',{staticClass:"col-lg-12 col-md-12 col-sm-12"},[_c('RadioButton',{attrs:{"name":"gender_id","value":_vm.jobs.gender_id,"options":_vm.options && _vm.options['gender'] ? _vm.options['gender'] : []},on:{"change":_vm.handleChangeRadio}})],1)])],1):_vm._e()],1),_c('CRow',{staticClass:"d-flex justify-content-end"},[_c('CButton',{staticClass:"btn-primary rounded-0 small mr-3",on:{"click":_vm.showAdvertModal}},[_vm._v("Add to Advert")])],1)],1)],1)],1),_c('div',{staticClass:"border-top d-flex justify-content-between py-3 flex-wrap"},[_c('div',{staticClass:"btn-add-job-cancel"},[_c('button',{staticClass:"btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary",attrs:{"name":"cancel"},on:{"click":function($event){return _vm.$parent.goToJobBoard()}}},[_vm._v(" Cancel ")])]),_c('div',{staticClass:"btn-add-job-submit"},[_c('button',{staticClass:"btn mr-2 rounded-2 px-5 btn-primary btn-lg mt-3",attrs:{"name":"draft","type":"button","disabled":_vm.isLoading},on:{"click":function($event){return _vm.onSave('draft')}}},[(_vm.isLoading == 'draft')?_c('CSpinner',{staticClass:"spinner-border-sm text-white m-1"}):_vm._e(),_vm._v("Save as Draft ")],1),_c('button',{staticClass:"btn rounded-2 px-5 btn-primary btn-lg mt-3",attrs:{"name":"next","type":"button","disabled":_vm.isLoading},on:{"click":function($event){return _vm.onSave('next')}}},[_vm._v(" Next "),(_vm.isLoading == 'next')?_c('CSpinner',{staticClass:"spinner-border-sm text-white m-1"}):_vm._e()],1)])])],1)]}}])}),_c('AdvertModal',{attrs:{"isShowPopup":_vm.isShowPopup,"jobData":_vm.jobs}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }