<template>
  <div v-if="!fetching" class="candidate-profile col-sm-9" style="margin: auto">
    <div class="d-flex align-items-center justify-content-between flex-wrap">
      <h3 class="m-1 pt-1 text-primary">Create Job</h3>
      <div class="w-100 d-flex justify-content-start sticky-header pt-3">
        <Stepper
          :options="stepperOptions"
          :activeBlock="block"
          :goTo="slideTo"
        ></Stepper>
        <h6
          v-if="job_id"
          class="pb-2 text-primary mt-1 font-weight-bold"
          style="white-space: nowrap"
        >
          JOB ID: {{ job_id }}
        </h6>
      </div>
    </div>
    <span v-if="block == 0">
      <JobInfo @step="gotoStep"></JobInfo>
    </span>
    <span v-if="block == 1">
      <JobAdditionalInfo @step="gotoStep"></JobAdditionalInfo>
    </span>
    <span v-if="block == 2">
      <JobTemplate @step="gotoStep"></JobTemplate>
    </span>
    <span v-if="block == 3">
      <JobPreview @step="gotoStep"></JobPreview>
    </span>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Stepper from "@/components/reusable/bars/stepper.vue";
import JobInfo from "@/containers/AddJobs/JobInformation";
import JobTemplate from "@/containers/AddJobs/JobTemplate";
import JobAdditionalInfo from "@/containers/AddJobs/JobAdditionalInfo";
import JobPreview from "@/containers/AddJobs/jobPreview";

export default {
  name: "AddJobs",
  components: {
    Stepper,
    JobInfo,
    JobAdditionalInfo,
    JobTemplate,
    JobPreview,
  },
  data() {
    return {
      block: 0,
      stepperOptions: {
        headers: [
          {
            title: "",
            tooltipTitle: "Job Information",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Additional Information",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Create Job",
            tooltipPlacement: "bottom-start",
          },
          {
            title: "",
            tooltipTitle: "Publish Job",
            tooltipPlacement: "bottom-start",
          },
        ],
      },
      fetching: false,
      jobUUID: null,
    };
  },
  computed: {
    ...mapGetters(["getCustomerId", "jobById", "getIsChildUser", "getOrgIDFromAccessToken"]),
    job_id(){
      return this.jobById?.job_id || null;
    }
  },
  created() {
    this.fetchInitialData();
  },
  methods: {
    ...mapActions([
      "showLoader",
      "hideLoader",
      "initJobsFetchOptions",
      "fetchJobByIDWithInitialOptions",
      "resetJobByID",
      "fetchOrganizationId",
    ]),
    slideTo(event, obj) {
      if (event < this.block) {
        this.updateURLQuery(event);
      } else {
        setTimeout(() => {
          obj.currentPosition = this.block;
        }, 0);
      }
    },
    gotoStep(step) {
      this.updateURLQuery(step);
      this.$store.dispatch("scrollTop");
    },
    goToJobBoard() {
      this.$router.push({ path: "/jobs-list/status/active" });
    },
    updateURLQuery(step = 0) {
      let { query } = this.$route;
      this.$router.push({ query: { ...query, step: step } });
    },
    fetchInitialData() {
      this.fetching = true;
      this.showLoader();
      const {
        query: { uuid: qid },
        params: { uuid: pid },
      } = this.$route;
      this.jobUUID = qid || pid;
      // Fetching initial data for job
      this.initJobsFetchOptions().then(async () => {
        if (this.jobUUID) {
          // Fetching job data
          await this.fetchJobByIDWithInitialOptions(this.jobUUID);
        } else {
          // Clearing cache
          this.resetJobByID();
        }
        let organisation_id = null;
        // if (this.getIsChildUser && this.getOrgIDFromAccessToken)
        if (this.getOrgIDFromAccessToken) organisation_id = this.getOrgIDFromAccessToken || null;
        if (this.getCustomerId) {
          await this.fetchOrganizationId({
            customer_uid: this.getCustomerId,
            organisation_id,
          });
        }
        this.hideLoader();
        this.fetching = false;
      });
      let { query } = this.$route;
      if (!query?.step) {
        this.$router.push({ query: { step: 0 } });
      } else {
        this.block = parseInt(query?.step);
      }
    },
  },
  watch: {
    "$route.query": function() {
      let {
        name,
        query: { id, step },
      } = this.$route;
      if (!id && step && name == "AddJobs") {
        this.resetJobByID();
        this.updateURLQuery();
        this.fetchInitialData();
      }
      this.block = parseInt(step);
    },
  },
};
</script>
