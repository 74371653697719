<template>
  <div class="add-job pb-4">
    <LoadingOverlay v-if="!isFetchingJobData" />
    <CCardGroup class="has-loading-overlay">
      <CCard>
        <CCardBody class="p-3">
          <ValidationObserver ref="JobAdditionalInfo" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <h5 class="text-primary">Additional Information</h5>
              <CRow class="mt-3">
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Full Time</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="full_time"
                        :value="jobs.full_time"
                        :options="
                          options && options['workingHours']
                            ? options['workingHours']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Part Time</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="part_time"
                        :value="jobs.part_time"
                        :options="
                          options && options['workingHours']
                            ? options['workingHours']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Permanent Work</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="permanent_work"
                        :value="jobs.permanent_work"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="3" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Temporary Work</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="temporary_work"
                        :value="jobs.temporary_work"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="3" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">Visiting/Locum</label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <RadioButton
                        name="visiting_locum"
                        :value="jobs.visiting_locum"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol
                  class="mb-3 col-md-6"
                  v-if="
                    jobs.working_hours &&
                      jobs.working_hours.detail_number != null
                  "
                >
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Working Hours (Per Week)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        :rules="{ max_value_hours: 48 }"
                        v-slot="{ errors }"
                      >
                        <TextInput
                          name="working_hours"
                          type="Number"
                          :min="0"
                          :max="48"
                          :value="
                            jobs.working_hours &&
                              jobs.working_hours.detail_number
                          "
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol
                  class="mb-3 col-md-6"
                  v-if="
                    jobs.working_days && jobs.working_days.detail_number != null
                  "
                >
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Working Days (per week)
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider
                        :rules="{ max_value_days: 7 }"
                        v-slot="{ errors }"
                      >
                        <TextInput
                          name="working_days"
                          type="Number"
                          :min="0"
                          :max="7"
                          :value="
                            jobs.working_days && jobs.working_days.detail_number
                          "
                          @input="handleInput"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>

              <CRow>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Hospital or Clinic Based
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <TextInput
                        name="organisation_type"
                        :value="jobs.organisation_type"
                        @input="handleInput"
                        :disabled="true"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Additional Information
                      <small>(Private)</small>
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <TextareaInput
                        name="additional_info"
                        :value="jobs.additional_info"
                        @input="handleInput"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </form>
          </ValidationObserver>
        </CCardBody>
      </CCard>
    </CCardGroup>
    <div
      class="row-sm my-2 action-group d-flex justify-content-between flex-wrap"
    >
      <div class="btn-add-job-cancel">
        <button
          name="cancel"
          class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg"
          @click="$parent.goToJobBoard()"
        >
          Cancel
        </button>
      </div>
      <div class="btn-add-job-submit">
        <button
          name="Back"
          class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg mr-2"
          @click="onSave('back', 0)"
          :disabled="isLoading"
        >
          Back
        </button>

        <button
          name="draft"
          type="button"
          class="btn rounded-2 px-5 btn-primary btn-lg mt-3 mr-2"
          @click="onSave('draft')"
          :disabled="isLoading"
        >
          Save as Draft
          <CSpinner
            v-if="isLoading == 'draft'"
            class="spinner-border-sm text-white m-1"
          />
        </button>
        <button
          name="next_page"
          type="button"
          class="btn rounded-2 px-5 btn-primary btn-lg mt-3"
          @click="onSave('next', 2)"
          :disabled="isLoading"
        >
          Next
          <CSpinner
            v-if="isLoading == 'next'"
            class="spinner-border-sm text-white m-1"
          />
        </button>
      </div>
    </div>
    <CModal
      id="ConfirmationModal"
      color="primary"
      :show.sync="confirmationModal.isShowPopup"
    >
      <template #header>
        <h5>NOTIFICATION</h5>
      </template>
      <p>{{ confirmationModal.content }}</p>
      <template #footer>
        <div>
          <span v-for="button in ['No', 'Yes']" :key="button">
            <CButton
              class="ml-2"
              :color="button == 'No' ? 'secondary' : 'primary'"
              @click="confirmationModalCallBack(button)"
            >
              {{ button }}
            </CButton>
          </span>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import AddJob from "./AddJob";
import { max_value, numeric } from "vee-validate/dist/rules";
import { extend } from "vee-validate";

extend("max_value_hours", {
  ...max_value,
  message: "Value cannot be greater than 48",
});
extend("max_value_days", {
  ...max_value,
  message: "Value cannot be greater than 7",
});
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("minMaxCheck", {
  params: ["salary_min"],
  validate(value, { salary_min }) {
    if (value - salary_min > 0) {
      return true;
    }
  },
  message: "Should be greater than min salary",
});

export default {
  mixins: [AddJob],
  data() {
    return {
      jobs: {},
      payload: {},
      isLoading: false,
      confirmationModal: {
        isShowPopup: false,
        content:
          "Your advert content will be updated - are you sure to proceed?",
      },
      isEditJob: false,
    };
  },
  computed: {
    ...mapGetters(["getOrgJobDetails", "getCurrency"]),
    isFetchingJobData() {
      if (Object.keys(this.jobById).length) {
        let jobs = this.jobById;

        const currency = jobs?.organisation?.country?.currency;
        this.jobs = {
          ...this.jobById,
          // target_salary_currency_id: jobs?.target_salary_currency_id
          //   ? this.getCurrencyForSelectedID(jobs?.target_salary_currency_id)
          //   : {
          //       label: currency.currency_code,
          //       code: currency.currency_id,
          //     },
          // salary_currency_id: jobs?.salary_currency_id
          //   ? this.getCurrencyForSelectedID(jobs?.salary_currency_id)
          //   : {
          //       label: currency.currency_code,
          //       code: currency.currency_id,
          //     },
          // salary: jobs?.preferred_in_advert?.includes("salary") ? true : false,
        };

        let working_days = [];
        let working_hours = [];
        let call_on = [];
        let call_on_radio = null;
        let first_visit_working_hours = false;
        let first_visit_working_days = false;
        let first_visit_call_on = false;
        if (
          this.jobById?.job_details &&
          Object.keys(this.jobById?.job_details).length
        ) {
          working_days = this.jobById?.job_details.filter((element) => {
            return element.organisation_job_detail.job_detail_type_id == 1;
          });
          working_hours = this.jobById?.job_details.filter((element) => {
            return element.organisation_job_detail.job_detail_type_id == 2;
          });
          call_on = this.jobById?.job_details.filter((element) => {
            return element.organisation_job_detail.job_detail_type_id == 6;
          });
          if (call_on.length) {
            call_on_radio = true;
          }
        }
        if (Object.keys(this.getOrgJobDetails).length) {
          if (!working_days.length) {
            working_days = this.getOrgJobDetails.filter((element) => {
              return element.job_detail_type_id == 1;
            });
            if (working_days.length) {
              first_visit_working_days = true;
              working_days[0].detail_text = working_days[0].description;
            }
          }
          if (!working_hours.length) {
            working_hours = this.getOrgJobDetails.filter((element) => {
              return element.job_detail_type_id == 2;
            });
            if (working_hours.length) {
              first_visit_working_hours = true;
              working_hours[0].detail_text = working_hours[0].description;
            }
          }
          if (!call_on.length) {
            call_on = this.getOrgJobDetails.filter((element) => {
              return element.job_detail_type_id == 6;
            });
            if (call_on.length) first_visit_call_on = true;
          }
        }
        this.jobs = {
          ...this.jobs,
          working_days: working_days[0],
          working_hours: working_hours[0],
          call_on_radio: call_on_radio,
          call_on: call_on_radio ? call_on[0] : call_on[1],
          first_visit_working_days: first_visit_working_days,
          first_visit_working_hours: first_visit_working_hours,
          first_visit_call_on: first_visit_call_on,
        };
      }
      return true;
    },
    options() {
      return {
        workingHours: [
          { id: "yes", label: "Yes" },
          { id: "no", label: "No" },
          { id: "flexible", label: "Flexible" },
        ],
        boolean: [
          { id: true, label: "Yes" },
          { id: false, label: "No" },
        ],
        currency: this.getCurrency || [],
      };
    },
    isNurseOrAHP() {
      return this.jobById?.candidate_type_id == 2 ||
        this.jobById?.candidate_type_id == 3
        ? true
        : false;
    },
  },
  mounted() {
    this.jobs = this.jobById;
    const {
      organisation_uid,
      part_time,
      full_time,
      temporary_work,
      permanent_work,
      organisation,
      organisation_type,
    } = this.jobById;
    if (full_time == null) {
      this.handleChangeRadio("full_time", "yes");
    }
    if (part_time == null) {
      this.handleChangeRadio("part_time", "no");
    }
    if (permanent_work == null) {
      this.handleChangeRadio("permanent_work", true);
    }
    if (temporary_work == null) {
      this.handleChangeRadio("temporary_work", false);
    }
    if (
      organisation_type == null ||
      organisation_type != organisation?.organisation_type?.organisation_type
    ) {
      this.handleInput(
        "organisation_type",
        organisation?.organisation_type?.organisation_type
      );
    }
    this.fetchOrgJobDetails({ organisation_id: organisation_uid });
    if (this.$router.currentRoute.name == "EditJobs") this.isEditJob = true;
  },

  methods: {
    onStep(step) {
      this.$emit("step", step);
    },
    handleInput(name, value) {
      let data = value;
      if (name === "working_hours") {
        data = {
          ...this.jobs.working_hours,
          detail_number: value,
          detail_text: `${value} hour(s) working a week`,
        };
      } else if (name === "working_days") {
        data = {
          ...this.jobs.working_days,
          detail_number: value,
          detail_text: `${value} day(s) working week`,
        };
      } else if (name === "call_on") {
        data = {
          ...this.jobs.call_on,
          detail_number: value,
          detail_text: `${value} hour(s) working for Call-On-Rota`,
        };
      }
      Vue.set(this.jobs, name, data);
      Vue.set(this.payload, name, data);
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value.id === false || value.code === false) {
        val = false;
      } else {
        val = value.id || value.code || value;
      }
      Vue.set(this.jobs, name, val);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
    },
    async onSave(selectedBtn, step) {
      const isValid = await this.$refs.JobAdditionalInfo.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "Please fill mandatory fields!",
        });
        return;
      }
      if (this.jobs.first_visit_working_hours && !this.payload.working_hours)
        this.payload.working_hours = this.jobs.working_hours;
      if (this.jobs.first_visit_working_days && !this.payload.working_days)
        this.payload.working_days = this.jobs.working_days;
      if (
        this.jobs.call_on_radio &&
        this.jobs.first_visit_call_on &&
        !this.payload.call_on
      )
        this.payload.call_on = this.jobs.call_on;
      this.submit(selectedBtn, step);
    },
    submit(selectedBtn, step) {
      this.isLoading = selectedBtn;
      const { job_id, job_uuid, customer_uid, organisation_uid } = this.jobById;
      if (Object.keys(this.payload).length) {
        let AdditionalPayload = [];
        let updatePayload = {};
        let payloadArray = Object.keys(this.payload);
        payloadArray.forEach((item) => {
          if (
            item === "working_hours" ||
            item === "working_days" ||
            item === "call_on"
          ) {
            AdditionalPayload.push(this.payload[item]);
          } else {
            updatePayload = {
              ...updatePayload,
              [item]: this.payload[item],
            };
          }
        });
        let appendAction = [];
        if (
          this.jobs.call_on_radio == false &&
          !this.jobs.first_visit_call_on
        ) {
          const { job_detail_id } = this.jobs.call_on;
          appendAction = this.deleteAdditionalInfo({
            job_id,
            job_detail_id,
          });
        }
        delete this.payload["call_on_radio"];

        if (AdditionalPayload?.length) {
          AdditionalPayload.forEach((val) => {
            const { organisation_job_detail_id } = val;
            let exist = this.jobById?.job_details?.some(
              (element) =>
                element.organisation_job_detail_id == organisation_job_detail_id
            );
            if (exist) {
              appendAction = this.updateAdditionalInfo({
                job_id,
                val: val,
              });
            } else {
              appendAction = this.createAdditionalInfo({
                job_id,
                val,
              });
            }
          });
        }
        if (Object.keys(updatePayload).length) {
          let updatedPayload = {
            ...updatePayload,
            organisation: organisation_uid,
            job_id,
            customer_uid,
          };

          appendAction = this.updateJobSteps(updatedPayload);
        }
        Promise.all([appendAction]).then(async (res) => {
          this.fetchJobByID(job_uuid).then(() => {
            this.isLoading = false;
            this.payload = {};
            if (
              selectedBtn == "next" &&
              this.isEditJob &&
              this.jobById?.advert_description
            ) {
              this.confirmationModal.isShowPopup = true;
              return;
            }
            if (selectedBtn != "draft") this.onStep(step);
          });
        });
      } else {
        this.isLoading = false;
        if (selectedBtn == "draft") {
          this.showToast({
            class: "bg-danger text-white",
            message: "No changes made !",
          });
          this.isLoading = false;
        }
        if (
          selectedBtn == "next" &&
          this.isEditJob &&
          this.jobById?.advert_description
        ) {
          this.confirmationModal.isShowPopup = true;
          return;
        }
        if (selectedBtn != "draft") this.onStep(step);
      }
    },
    confirmationModalCallBack(action) {
      this.confirmationModal.isShowPopup = false;
      if (action == "Yes") {
        this.onStep(2);
      }
    },
  },
};
</script>
