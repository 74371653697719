<template>
  <div class="create add-job pb-4">
    <LoadingOverlay v-if="!isFetchingJobData" />
    <ValidationObserver ref="newPageSix" v-slot="{ handleSubmit }">
      <form id="create" @submit.prevent="handleSubmit()">
        <CCardGroup class="has-loading-overlay">
          <CCard v-if="jobDesc">
            <CCardBody class="p-3">
              <div class="d-flex justify-content-between">
                <h5 class="main-title">Publish Job</h5>
                <button class="hand-icon action-button" @click="showHint()">
                  <CIcon
                    name="cil-info"
                    v-c-tooltip="{
                      content: `Publish Job - Customize your Job Advert by Inserting or Deleting the text below`,
                      placement: 'left',
                      appendToBody: true,
                    }"
                    class="hinticon"
                    placement="left"
                  />
                </button>
              </div>
              <CRow class="mb-3">
                <CCol md="6">
                  <CRow class="row mb-3">
                    <label class="required col-lg-12 col-md-12">
                      Expiry Date
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <DatePicker
                          name="expiry_review_date"
                          :value="jobs.expiry_review_date"
                          @input="handleDatePickerChange"
                          :showFromTmw="true"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" class="mb-3">
                  <CRow class="row">
                    <label class="col-lg-12 col-md-12">
                      Advertising Location
                    </label>
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <Select
                        name="advertisement_location"
                        :value="jobs.advertisement_location"
                        @input="handleChangeSelect"
                        :options="
                          options && options['advertisement_location']
                            ? options['advertisement_location']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="true"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol md="12">
                  <CRow class="row mb-3">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                      <rich-text-editor
                        name="advert_description"
                        :value="jobs.advert_description"
                        @input="handleInput"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </CCardBody>
          </CCard>
        </CCardGroup>
        <div
          class="row-sm my-2 action-group d-flex justify-content-between flex-wrap"
        >
          <div class="btn-add-job-cancel">
            <button
              name="cancel"
              class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg"
              @click="$parent.goToJobBoard()"
            >
              Cancel
            </button>
          </div>
          <div class="btn-add-job-submit">
            <button
              type="button"
              name="Back"
              class="btn rounded-2 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg mr-2"
              @click="onBack()"
              :disabled="isLoading"
            >
              Back
            </button>

            <button
              name="next_page"
              class="btn rounded-2 btn-primary btn-lg mt-3 mr-2"
              @click="onSave('saveDraft')"
              :disabled="isLoading"
            >
              Save as Draft
            </button>
            <button
              name="next_page"
              class="btn rounded-2 btn-primary btn-lg mt-3"
              @click="onSave('savePublish')"
              :disabled="isLoading"
            >
              {{
                jobs.job_users && jobs.job_users.length
                  ? "Send For Approval"
                  : "Preview & Publish"
              }}
            </button>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <CModal id="Preview Job" centered:true :show.sync="publishModal" size="lg">
      <template #header-wrapper>
        <header class="modal-header" style="background: #085d66e6; color: #fff">
          <h5
            class="modal-title px-3"
            style="font-size: 26px; font-weight: 300"
          >
            Preview Job
          </h5>
          <button
            @click="onCancelPublish"
            type="button"
            aria-label="Close"
            class="close"
          >
            ×
          </button>
        </header>
      </template>
      <template #body-wrapper>
        <jobPreview :jobDetails="jobs"></jobPreview>
      </template>
      <template #footer-wrapper>
        <div class="d-flex justify-content-between py-3 flex-wrap mx-2">
          <div class="btn-add-job-cancel">
            <button
              name="cancel"
              class="btn rounded-0 px-5 btn-lg mt-3 float-left btn-outline-primary btn-lg mr-2"
              @click="onCancelPublish"
            >
              Cancel
            </button>
          </div>
          <div class="btn-add-job-submit">
            <button
              name="publish_page"
              class="btn rounded-0 btn-primary btn-lg mt-3 px-4"
              @click="onPublish()"
            >
              Confirm
            </button>
          </div>
        </div>
      </template>
    </CModal>

    <CModal
      id="Save as Draft"
      title="Save as Draft"
      centered:true
      color="primary"
      :show.sync="draftModal"
    >
      <p>Please confirm you would like to save this job as a Draft Job?</p>
      <template #footer-wrapper>
        <div class="d-flex justify-content-end px-4">
          <button @click="onCancelDraft" class="btn btn-secondary mb-3 mr-3">
            Cancel
          </button>
          <button @click="onDraftSave" class="btn btn-primary mb-3">
            Confirm
          </button>
        </div>
      </template>
    </CModal>
  </div>
</template>

<script>
import Vue from "vue";
import jobPreview from "@/components/JobDetailPreview/JobDetailPreviewUpdated.vue";
import PreviewModal from "@/components/reusable/PreviewModal";
import AddJob from "./AddJob";

export default {
  components: {
    jobPreview,
    PreviewModal,
  },
  mixins: [AddJob],
  data() {
    return {
      jobs: {},
      payload: { step: 4 },
      publishModal: false,
      draftModal: false,
      AdditionalInfoDisp: [],
      isLoading: false,
    };
  },
  computed: {
    isFetchingJobData() {
      if (Object.keys(this.jobById).length) {
        let jobData = this.jobById;
        this.jobs = {
          ...this.jobById,
          expiry_review_date: jobData?.expiry_review_date
            ? new Date(jobData?.expiry_review_date)
            : null,
          advertisement_location: jobData?.advertisement_location?.length
            ? this.getAdLocationForSelectedID(jobData?.advertisement_location)
            : this.options["advertisement_location"][0],
          advert_description: jobData.advert_description,
        };
      }
      return true;
    },
    options() {
      return {
        advertisement_location: [
          { label: "Job Board", code: "job_board" },
          // { label: "Social Media", code: "social_media" },
        ],
      };
    },
    jobDesc() {
      const { job_id } = this.jobById;
      if (job_id) {
        this.jobDescription = this.jobSections("advert", this.jobById);
        // Additional Requirements
        if (this.additionalRequirements(this.jobById))
          this.jobDescription += `<h4 class="mt-3 mb-2"><strong>Additional Requirements</strong></h4>${this.additionalRequirements(
            this.jobById
          )}`;
        // Additional Information
        this.jobDescription += `<h4 class="mt-3"><strong>Additional Information:</strong></h4><ul class="mt-2 mb-1">`;
        // additional info - full time
        if (this.jobById.full_time != "no")
          this.jobDescription += `${
            this.jobById.full_time == "flexible"
              ? "<li>Full Time (Flexible)</li>"
              : "<li>Full Time</li>"
          }`;
        // additional info - part time
        if (this.jobById.part_time != "no")
          this.jobDescription += `${
            this.jobById.part_time == "flexible"
              ? "<li>Part Time (Flexible)</li>"
              : "<li>Part Time</li>"
          }`;
        // additional info - contract
        if (this.jobById.permanent_work)
          this.jobDescription += `<li>Permanent Work</li>`;
        if (this.jobById.temporary_work)
          this.jobDescription += `<li>Temporary Work</li>`;
        // job details like working hours, working days, working pattern
        if (this.jobById?.job_details?.length) {
          let AdditionalInfoDisp = this.jobById.job_details.reduce(
            (list, { detail_text }) => {
              return list + `<li>${detail_text}</li>`;
            },
            ""
          );
          this.jobDescription += AdditionalInfoDisp;
        }
        this.jobDescription += `</ul>`;
        Vue.set(this.jobs, "advert_description", this.jobDescription);
      }
      return true;
    },
  },

  methods: {
    onStep(step) {
      this.$emit("step", step);
    },
    async onSave(action) {
      const isValid = await this.$refs.newPageSix.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      } else {
        if (action === "saveDraft") {
          this.draftModal = true;
        } else if (action === "savePublish") {
          this.publishModal = true;
        }
      }
    },
    onCancelPublish() {
      this.publishModal = !this.publishModal;
    },
    onPublish() {
      this.payload = {
        ...this.payload,
        job_id: this.jobById.job_id,
        customer_uid: this.jobById.customer_uid,
        organisation: this.jobById.organisation.organisation_id,
        advertisement_location: this.jobs?.advertisement_location?.length
          ? this.jobs?.advertisement_location?.map((val) => val.code)
          : null,
        advert_description: this.jobs?.advert_description,
      };
      this.updateJobSteps({ ...this.payload, published: true }).then((res) => {
        this.publishModal = false;
        this.$router.push(`/jobs-list/status/active?job_uuid=${res.data.job_uuid}`);
      });
    },
    onDraftSave() {
      this.payload = {
        ...this.payload,
        job_id: this.jobById.job_id,
        customer_uid: this.jobById.customer_uid,
        organisation: this.jobById.organisation.organisation_id,
        advertisement_location: this.jobs?.advertisement_location?.length
          ? this.jobs?.advertisement_location?.map((val) => val.code)
          : null,
      };
      this.updateJobSteps({ ...this.payload, published: false }).then((res) => {
        this.draftModal = false;
        this.$router.push(`/jobs-list/status/active?job_uuid=${res.data.job_uuid}`);
      });
    },
    onBack() {
      this.isLoading = true;
      this.payload = {
        ...this.payload,
        job_id: this.jobById.job_id,
        customer_uid: this.jobById.customer_uid,
        organisation: this.jobById.organisation.organisation_id,
        advertisement_location: this.jobs?.advertisement_location?.length
          ? this.jobs?.advertisement_location?.map((val) => val.code)
          : null,
      };
      this.updateJobSteps({ ...this.payload }).then((res) => {
        this.draftModal = false;
        this.isLoading = false;
        this.onStep(2);
      });
    },
    onCancelDraft() {
      this.draftModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.add-btn {
  border-radius: 4px !important;
}
@media all and (max-width: 1024px) {
  .org-logo {
    width: 100%;
  }
  .btn-add-job-cancel {
    width: 100%;
    .btn {
      width: 100%;
    }
  }
  .btn-add-job-submit {
    width: 100%;
    .btn {
      width: 100%;
      margin-right: 0;
    }
  }
}
</style>
